<template>
  <v-dialog v-model="dialog" persistent :overlay="false" max-width="500px">
    <v-card>
      <v-card-title> Migração de usuários </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <app-select
              :items="whatsapp_instances"
              v-model="form.origin_instance_id"
              :item-text="(item) => getName(item)"
              clearable
              item-value="id"
              label="Instância de Origem"
            />
          </v-col>
          <v-col cols="12">
            <app-select
              :items="whatsapp_instances"
              v-model="form.destiny_instance_id"
              :item-text="(item) => getName(item)"
              clearable
              item-value="id"
              label="Instância de Destino"
            />
          </v-col>

          <v-col>
            <app-number-field
              v-model="form.quantity"
              label="Quantidade de usuários"
              :precision="0"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,

      form: {},

      whatsapp_instances: [],

      baseForm: {
        origin_instance_id: null,
        destiny_instance_id: null,
        quantity: null,
      },

      drivers: {
        "\\App\\Services\\ZApi": "Z-API",
        "\\App\\Services\\EvolutionApi": "Evo",
        "\\App\\Services\\ApiZap": "ApiZap",
        zapi: "Z-API",
      },

      status: {
        offline: "🔴",
        online: "🟢",
      },
      types: {
        session: "Sessão",
        billing: "Cobrança",
        company: "Empresa",
      },
    };
  },

  methods: {
    open() {
      this.$loading.start();
      this.$http
        .index("admin/whatsapp-instances", {
          type: "system",
        })
        .then((response) => {
          this.reset();
          this.whatsapp_instances = response.whatsapp_instances;
          this.dialog = true;
          this.$loading.finish();
        })
        .catch((error) => this.$loading.finish());
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    handleSave() {
      this.$loading.start();
      this.$http
        .store("admin/whatsapp-instances/migrate-users", this.form)
        .then((response) => {
          this.$loading.finish();

          console.log(response.companies_updated);

          this.$snackbar({
            message: `${response.companies_updated} usuários migrados com sucesso`,
            color: "success",
            timeout: 1000,
            showClose: false,
          });

          this.$emit("store");
        })
        .catch((error) => this.$loading.finish());
    },
    getName(item) {
      return `${item.name} | ${this.drivers[item.driver]} | ${
        this.types[item.type]
      } | ${this.status[item.info.status]}`;
    },
  },
};
</script>

<style>
</style>